@import url(~react-quill/dist/quill.snow.css);
@import url(~perfect-scrollbar/css/perfect-scrollbar.css);
@import url(~swiper/dist/css/swiper.min.css);
#root,
body,
html {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative; }

html {
  font-size: 16px; }

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: Roboto, "Helvetica Neue", sans-serif; }

div,
a {
  box-sizing: border-box; }

img {
  max-width: 100%; }

h1,
h2,
h3,
h4,
h5,
h6,
.card-title {
  color: var(--text-body) !important;
  font-weight: 500; }

.layout1,
.layout2,
.MuiPaper-root,
.MuiTableCell-body,
.egret-customizer {
  color: var(--text-body) !important; }

.m-0 {
  margin: 0px !important; }

.mt-0 {
  margin-top: 0px !important; }

.mr-0 {
  margin-right: 0px !important; }

.mb-0 {
  margin-bottom: 0px !important; }

.ml-0 {
  margin-left: 0px !important; }

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.p-0 {
  padding: 0px !important; }

.pt-0 {
  padding-top: 0px !important; }

.pr-0 {
  padding-right: 0px !important; }

.pb-0 {
  padding-bottom: 0px !important; }

.pl-0 {
  padding-left: 0px !important; }

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.m-4 {
  margin: 4px !important; }

.mt-4 {
  margin-top: 4px !important; }

.mr-4 {
  margin-right: 4px !important; }

.mb-4 {
  margin-bottom: 4px !important; }

.ml-4 {
  margin-left: 4px !important; }

.mx-4 {
  margin-left: 4px !important;
  margin-right: 4px !important; }

.my-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important; }

.p-4 {
  padding: 4px !important; }

.pt-4 {
  padding-top: 4px !important; }

.pr-4 {
  padding-right: 4px !important; }

.pb-4 {
  padding-bottom: 4px !important; }

.pl-4 {
  padding-left: 4px !important; }

.px-4 {
  padding-left: 4px !important;
  padding-right: 4px !important; }

.py-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important; }

.m-8 {
  margin: 8px !important; }

.mt-8 {
  margin-top: 8px !important; }

.mr-8 {
  margin-right: 8px !important; }

.mb-8 {
  margin-bottom: 8px !important; }

.ml-8 {
  margin-left: 8px !important; }

.mx-8 {
  margin-left: 8px !important;
  margin-right: 8px !important; }

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important; }

.p-8 {
  padding: 8px !important; }

.pt-8 {
  padding-top: 8px !important; }

.pr-8 {
  padding-right: 8px !important; }

.pb-8 {
  padding-bottom: 8px !important; }

.pl-8 {
  padding-left: 8px !important; }

.px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important; }

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important; }

.m-10 {
  margin: 10px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mr-10 {
  margin-right: 10px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.ml-10 {
  margin-left: 10px !important; }

.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.p-10 {
  padding: 10px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pr-10 {
  padding-right: 10px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.pl-10 {
  padding-left: 10px !important; }

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important; }

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.m-12 {
  margin: 12px !important; }

.mt-12 {
  margin-top: 12px !important; }

.mr-12 {
  margin-right: 12px !important; }

.mb-12 {
  margin-bottom: 12px !important; }

.ml-12 {
  margin-left: 12px !important; }

.mx-12 {
  margin-left: 12px !important;
  margin-right: 12px !important; }

.my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important; }

.p-12 {
  padding: 12px !important; }

.pt-12 {
  padding-top: 12px !important; }

.pr-12 {
  padding-right: 12px !important; }

.pb-12 {
  padding-bottom: 12px !important; }

.pl-12 {
  padding-left: 12px !important; }

.px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important; }

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important; }

.m-16 {
  margin: 16px !important; }

.mt-16 {
  margin-top: 16px !important; }

.mr-16 {
  margin-right: 16px !important; }

.mb-16 {
  margin-bottom: 16px !important; }

.ml-16 {
  margin-left: 16px !important; }

.mx-16 {
  margin-left: 16px !important;
  margin-right: 16px !important; }

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important; }

.p-16 {
  padding: 16px !important; }

.pt-16 {
  padding-top: 16px !important; }

.pr-16 {
  padding-right: 16px !important; }

.pb-16 {
  padding-bottom: 16px !important; }

.pl-16 {
  padding-left: 16px !important; }

.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important; }

.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important; }

.m-20 {
  margin: 20px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mr-20 {
  margin-right: 20px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.ml-20 {
  margin-left: 20px !important; }

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.p-20 {
  padding: 20px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pr-20 {
  padding-right: 20px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pl-20 {
  padding-left: 20px !important; }

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.m-24 {
  margin: 24px !important; }

.mt-24 {
  margin-top: 24px !important; }

.mr-24 {
  margin-right: 24px !important; }

.mb-24 {
  margin-bottom: 24px !important; }

.ml-24 {
  margin-left: 24px !important; }

.mx-24 {
  margin-left: 24px !important;
  margin-right: 24px !important; }

.my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important; }

.p-24 {
  padding: 24px !important; }

.pt-24 {
  padding-top: 24px !important; }

.pr-24 {
  padding-right: 24px !important; }

.pb-24 {
  padding-bottom: 24px !important; }

.pl-24 {
  padding-left: 24px !important; }

.px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important; }

.py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }

.m-28 {
  margin: 28px !important; }

.mt-28 {
  margin-top: 28px !important; }

.mr-28 {
  margin-right: 28px !important; }

.mb-28 {
  margin-bottom: 28px !important; }

.ml-28 {
  margin-left: 28px !important; }

.mx-28 {
  margin-left: 28px !important;
  margin-right: 28px !important; }

.my-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important; }

.p-28 {
  padding: 28px !important; }

.pt-28 {
  padding-top: 28px !important; }

.pr-28 {
  padding-right: 28px !important; }

.pb-28 {
  padding-bottom: 28px !important; }

.pl-28 {
  padding-left: 28px !important; }

.px-28 {
  padding-left: 28px !important;
  padding-right: 28px !important; }

.py-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important; }

.m-30 {
  margin: 30px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mr-30 {
  margin-right: 30px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.ml-30 {
  margin-left: 30px !important; }

.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.p-30 {
  padding: 30px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pr-30 {
  padding-right: 30px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.pl-30 {
  padding-left: 30px !important; }

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.m-32 {
  margin: 32px !important; }

.mt-32 {
  margin-top: 32px !important; }

.mr-32 {
  margin-right: 32px !important; }

.mb-32 {
  margin-bottom: 32px !important; }

.ml-32 {
  margin-left: 32px !important; }

.mx-32 {
  margin-left: 32px !important;
  margin-right: 32px !important; }

.my-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important; }

.p-32 {
  padding: 32px !important; }

.pt-32 {
  padding-top: 32px !important; }

.pr-32 {
  padding-right: 32px !important; }

.pb-32 {
  padding-bottom: 32px !important; }

.pl-32 {
  padding-left: 32px !important; }

.px-32 {
  padding-left: 32px !important;
  padding-right: 32px !important; }

.py-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important; }

.m-36 {
  margin: 36px !important; }

.mt-36 {
  margin-top: 36px !important; }

.mr-36 {
  margin-right: 36px !important; }

.mb-36 {
  margin-bottom: 36px !important; }

.ml-36 {
  margin-left: 36px !important; }

.mx-36 {
  margin-left: 36px !important;
  margin-right: 36px !important; }

.my-36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important; }

.p-36 {
  padding: 36px !important; }

.pt-36 {
  padding-top: 36px !important; }

.pr-36 {
  padding-right: 36px !important; }

.pb-36 {
  padding-bottom: 36px !important; }

.pl-36 {
  padding-left: 36px !important; }

.px-36 {
  padding-left: 36px !important;
  padding-right: 36px !important; }

.py-36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important; }

.m-40 {
  margin: 40px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mr-40 {
  margin-right: 40px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.ml-40 {
  margin-left: 40px !important; }

.mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.p-40 {
  padding: 40px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pr-40 {
  padding-right: 40px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.pl-40 {
  padding-left: 40px !important; }

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.m-44 {
  margin: 44px !important; }

.mt-44 {
  margin-top: 44px !important; }

.mr-44 {
  margin-right: 44px !important; }

.mb-44 {
  margin-bottom: 44px !important; }

.ml-44 {
  margin-left: 44px !important; }

.mx-44 {
  margin-left: 44px !important;
  margin-right: 44px !important; }

.my-44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important; }

.p-44 {
  padding: 44px !important; }

.pt-44 {
  padding-top: 44px !important; }

.pr-44 {
  padding-right: 44px !important; }

.pb-44 {
  padding-bottom: 44px !important; }

.pl-44 {
  padding-left: 44px !important; }

.px-44 {
  padding-left: 44px !important;
  padding-right: 44px !important; }

.py-44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important; }

.m-48 {
  margin: 48px !important; }

.mt-48 {
  margin-top: 48px !important; }

.mr-48 {
  margin-right: 48px !important; }

.mb-48 {
  margin-bottom: 48px !important; }

.ml-48 {
  margin-left: 48px !important; }

.mx-48 {
  margin-left: 48px !important;
  margin-right: 48px !important; }

.my-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important; }

.p-48 {
  padding: 48px !important; }

.pt-48 {
  padding-top: 48px !important; }

.pr-48 {
  padding-right: 48px !important; }

.pb-48 {
  padding-bottom: 48px !important; }

.pl-48 {
  padding-left: 48px !important; }

.px-48 {
  padding-left: 48px !important;
  padding-right: 48px !important; }

.py-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important; }

@media screen and (max-width: 767px) {
  .pb-30 {
    padding-bottom: 16px !important; } }

.pb-2 {
  padding-bottom: 2px; }

.pb-3 {
  padding-bottom: 3px; }

.px-80 {
  padding-right: 80px;
  padding-left: 80px; }
  @media screen and (max-width: 460px) {
    .px-80 {
      padding-right: 16px;
      padding-left: 16px; } }

.py-80 {
  padding-top: 80px;
  padding-bottom: 80px; }

.py-2 {
  padding-top: 2px;
  padding-bottom: 2px; }

.p-sm-30 {
  padding: 30px; }
  @media screen and (max-width: 767px) {
    .p-sm-30 {
      padding: 16px; } }

.px-sm-30 {
  padding: 0px 30px; }
  @media screen and (max-width: 767px) {
    .px-sm-30 {
      padding: 0px 16px; } }

.pr-sm-30 {
  padding-right: 30px !important; }
  @media screen and (max-width: 767px) {
    .pr-sm-30 {
      padding-right: 16px !important; } }

.p-sm-24 {
  padding: 24px !important; }
  @media screen and (max-width: 767px) {
    .p-sm-24 {
      padding: 16px !important; } }

.px-sm-24 {
  padding: 0px 24px !important; }
  @media screen and (max-width: 767px) {
    .px-sm-24 {
      padding: 0px 16px !important; } }

.pt-sm-24 {
  padding-top: 24px !important; }
  @media screen and (max-width: 767px) {
    .pt-sm-24 {
      padding-top: 16px !important; } }

.pl-sm-24 {
  padding-left: 24px !important; }
  @media screen and (max-width: 767px) {
    .pl-sm-24 {
      padding: 12px !important; } }

.m-auto {
  margin: auto !important; }

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mb-38 {
  margin-bottom: 38px; }

.mb-14 {
  margin-bottom: 14px !important; }

.mb-1 {
  margin-bottom: 1px; }

.mb-2 {
  margin-bottom: 2px; }

.ml--12 {
  margin-left: -12px; }

.m-sm-30 {
  margin: 30px; }
  @media screen and (max-width: 767px) {
    .m-sm-30 {
      margin: 16px; } }

.mb-sm-30 {
  margin-bottom: 30px; }
  @media screen and (max-width: 767px) {
    .mb-sm-30 {
      margin-bottom: 16px; } }

.mx-sm-30 {
  margin-right: 30px;
  margin-left: 30px; }
  @media screen and (max-width: 767px) {
    .mx-sm-30 {
      margin-right: 16px;
      margin-left: 16px; } }

.w-100 {
  width: 100%; }

.w-220 {
  width: 220px; }

.h-100 {
  height: 100% !important; }

.h-150 {
  height: 150px !important; }

.h-320 {
  height: 320px; }

.h-100vh {
  height: 100vh; }

.h-100vh-80 {
  height: calc(100vh - 80px); }

.h-500 {
  height: 500px !important; }

.h-700 {
  height: 700px; }

.h-800 {
  height: 800px; }

.h-48 {
  height: 48px; }

.h-70 {
  height: 70px; }

.h-32 {
  height: 32px; }

.h-56 {
  height: 56px; }

.size-36 {
  height: 36px !important;
  width: 36px !important; }

.size-24 {
  height: 24px !important;
  width: 24px !important; }

.bg-primary {
  background: var(--primary) !important; }

.bg-secondary {
  background: var(--secondary) !important; }

.bg-error {
  background: var(--error) !important; }

.bg-white {
  background: #fff !important;
  color: inherit; }

.bg-default {
  background: var(--bg-default) !important; }

.bg-paper {
  background: var(--bg-paper); }

.bg-light-gray {
  background: rgba(0, 0, 0, 0.08) !important; }

.bg-dark {
  background: #000000;
  color: #fff; }

.bg-light-dark {
  background: #212121;
  color: white; }

.bg-error {
  background: var(--error) !important;
  color: white !important; }

[class^="bg-light-"],
[class*=" bg-light-"] {
  position: relative;
  z-index: 0; }
  [class^="bg-light-"]::after,
  [class*=" bg-light-"]::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.15;
    z-index: -1;
    border-radius: 8px; }

.bg-light-primary::after {
  background: var(--primary); }

.bg-light-secondary {
  position: relative;
  z-index: 0; }
  .bg-light-secondary::after {
    background: var(--secondary); }

.bg-light-error {
  position: relative;
  z-index: 0; }
  .bg-light-error::after {
    background: var(--error); }

.bg-green {
  background: #08ad6c !important; }

.bg-light-green {
  background: rgba(8, 173, 108, 0.5) !important; }

.bg-transperant {
  background: transparent !important; }

.text-white {
  color: #fff !important; }

.text-muted-white {
  color: rgba(255, 255, 255, 0.54) !important; }

.text-light-white {
  color: rgba(255, 255, 255, 0.54) !important; }

.text-muted {
  color: var(--text-muted) !important; }

.text-hint {
  color: var(--text-hint) !important; }

.text-gray {
  color: rgba(0, 0, 0, 0.74) !important; }

.text-brand {
  color: var(--primary) !important; }

.text-primary {
  color: var(--primary) !important; }

.text-secondary {
  color: var(--secondary) !important; }

.text-green {
  color: #08ad6c !important; }

.text-error {
  color: var(--error) !important; }

.gray-on-hover {
  transition: background 250ms ease; }
  .gray-on-hover:hover {
    background: rgba(0, 0, 0, 0.054); }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.5rem;
  line-height: 1.1;
  color: inherit; }

.h1,
h1 {
  font-size: 2rem; }

.h2,
h2 {
  font-size: 1.75rem; }

.h3,
h3 {
  font-size: 1.5rem; }

.h4,
h4 {
  font-size: 1.25rem; }

.h5,
h5 {
  font-size: 1rem; }

.h6,
h6 {
  font-size: 0.875rem; }

a {
  text-decoration: none;
  color: inherit; }

.caption {
  font: var(--font-caption); }

.subheading-1 {
  font: var(--font-subheading-1); }

.subheading-2 {
  font: var(--font-subheading-2); }

.heading {
  font: var(--font-heading); }

.title {
  font: var(--font-title); }

.display-1 {
  font: var(--font-display-1); }

.display-2 {
  font: var(--font-display-2); }

.display-3 {
  font: var(--font-display-3); }

.display-4 {
  font: var(--font-display-4); }

.capitalize {
  text-transform: capitalize !important; }

.uppercase {
  text-transform: uppercase !important; }

.lowercase {
  text-transform: lowercase !important; }

.font-weight-normal {
  font-weight: normal !important; }

.font-weight-300,
.font-weight-light {
  font-weight: 300 !important; }

.font-weight-500,
.font-weight-semi-bold {
  font-weight: 500 !important; }

.font-weight-600,
.font-weight-bold {
  font-weight: 600 !important; }

.font-size-13 {
  font-size: 13px; }

.font-size-14 {
  font-size: 14px; }

.font-size-16 {
  font-size: 16px; }

.font-size-18 {
  font-size: 18px; }

.font-size-20 {
  font-size: 20px; }

.font-size-22 {
  font-size: 22px; }

.font-size-24 {
  font-size: 24px; }

.font-size-30 {
  font-size: 30px !important; }

.font-size-32 {
  font-size: 32px; }

.text-small {
  font-size: 0.8125rem !important; }

.white-space-pre-line {
  white-space: pre-wrap;
  word-break: break-word; }

.white-space-pre {
  white-space: pre; }

.fade-in {
  animation: fade-in 1s cubic-bezier(0.17, 0.67, 0.83, 0.67); }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes spin {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

.spin {
  animation: spin 3s infinite linear; }

.display-none {
  display: none; }

.display-block {
  display: block; }

.flex {
  display: flex; }

.flex-column {
  display: flex;
  flex-direction: column; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-start {
  justify-content: flex-start !important; }

.flex-center {
  justify-content: center; }

.flex-end {
  justify-content: flex-end; }

.flex-space-between {
  justify-content: space-between !important; }

.flex-space-around {
  justify-content: space-around; }

.flex-middle {
  align-items: center; }

.flex-top {
  align-items: flex-start; }

.flex-stretch {
  align-items: stretch; }

.flex-grow-1 {
  flex-grow: 1; }

.flex-grow-2 {
  flex-grow: 2; }

.flex-grow-3 {
  flex-grow: 3; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden; }

.scroll-y {
  overflow-x: hidden;
  overflow-y: scroll; }

.position-relative {
  position: relative; }

.position-bottom {
  position: absolute;
  bottom: 0; }

.text-center {
  text-align: center; }

.text-middle {
  vertical-align: middle; }

.text-align-right {
  text-align: right; }

.text-align-left {
  text-align: left; }

.x-center {
  left: 50%;
  transform: translateX(-50%); }

.y-center {
  top: 50%;
  transform: translateY(-50%); }

.cursor-pointer {
  cursor: pointer; }

.cursor-move {
  cursor: move; }

.border-radius-0 {
  border-radius: 0px !important;
  overflow: hidden; }

.border-radius-4 {
  border-radius: 4px !important;
  overflow: hidden; }

.border-radius-8 {
  border-radius: 8px !important;
  overflow: hidden; }

.border-radius-circle {
  border-radius: 50% !important;
  overflow: hidden; }

.border-none {
  border: none !important; }

.elevation-z0 {
  box-shadow: var(--elevation-z0); }

.elevation-z1 {
  box-shadow: var(--elevation-z1); }

.elevation-z2 {
  box-shadow: var(--elevation-z2); }

.elevation-z3 {
  box-shadow: var(--elevation-z3); }

.elevation-z4 {
  box-shadow: var(--elevation-z4); }

.elevation-z5 {
  box-shadow: var(--elevation-z5); }

.elevation-z6 {
  box-shadow: var(--elevation-z6); }

.elevation-z7 {
  box-shadow: var(--elevation-z7); }

.elevation-z8 {
  box-shadow: var(--elevation-z8); }

.elevation-z9 {
  box-shadow: var(--elevation-z9); }

.elevation-z10 {
  box-shadow: var(--elevation-z10); }

.elevation-z11 {
  box-shadow: var(--elevation-z11); }

.elevation-z12 {
  box-shadow: var(--elevation-z12); }

.elevation-z13 {
  box-shadow: var(--elevation-z13); }

.elevation-z14 {
  box-shadow: var(--elevation-z14); }

.elevation-z15 {
  box-shadow: var(--elevation-z15); }

.elevation-z16 {
  box-shadow: var(--elevation-z16); }

.elevation-z17 {
  box-shadow: var(--elevation-z17); }

.elevation-z18 {
  box-shadow: var(--elevation-z18); }

.elevation-z19 {
  box-shadow: var(--elevation-z19); }

.elevation-z20 {
  box-shadow: var(--elevation-z20); }

.elevation-z21 {
  box-shadow: var(--elevation-z21); }

.elevation-z22 {
  box-shadow: var(--elevation-z22); }

.elevation-z23 {
  box-shadow: var(--elevation-z23); }

.elevation-z24 {
  box-shadow: var(--elevation-z24); }

.bg-dotted {
  background: url("/assets/images/dots.png"), linear-gradient(90deg, #7467ef -19.83%, #ada5f6 189.85%);
  background-repeat: no-repeat;
  background-size: 100%; }

.circular-image-small {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden; }

.card {
  transition: all 0.3s ease; }
  .card:hover {
    box-shadow: var(--elevation-z12); }

.card-title {
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 500; }

.card-subtitle {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.54); }
  .theme-dark .card-subtitle {
    color: rgba(255, 255, 255, 0.54); }

@media screen and (max-width: 767px) {
  .hide-on-mobile {
    display: none !important; } }

.VictoryContainer svg {
  height: 100% !important; }

.box-shadow-none {
  box-shadow: none !important; }

.circle-44 {
  height: 44px !important;
  width: 44px !important; }

.circle-32 {
  height: 32px !important;
  min-height: 32px !important;
  width: 32px !important; }
  .circle-32 .MuiFab-root {
    min-height: 32px !important; }
  .circle-32 .MuiIcon-root {
    font-size: 13px !important; }

.show-on-mobile {
  display: none !important; }
  @media screen and (max-width: 767px) {
    .show-on-mobile {
      display: inherit !important; } }

.hide-on-mobile {
  display: inherit; }
  @media screen and (max-width: 767px) {
    .hide-on-mobile {
      display: none; } }

.invisible-on-pc {
  visibility: hidden; }
  @media screen and (max-width: 767px) {
    .invisible-on-pc {
      visibility: visible; } }

.highlight-js pre {
  white-space: pre-line; }

.react-vis-magic-css-import-rule {
  display: inherit; }

.rv-treemap {
  font-size: 12px;
  position: relative; }

.rv-treemap__leaf {
  overflow: hidden;
  position: absolute; }

.rv-treemap__leaf--circle {
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center; }

.rv-treemap__leaf__content {
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis; }

.rv-xy-plot {
  color: #c3c3c3;
  position: relative; }

.rv-xy-plot canvas {
  pointer-events: none; }

.rv-xy-plot .rv-xy-canvas {
  pointer-events: none;
  position: absolute; }

.rv-xy-plot__inner {
  display: block; }

.rv-xy-plot__axis__line {
  fill: none;
  stroke-width: 2px;
  stroke: #e6e6e9; }

.rv-xy-plot__axis__tick__line {
  stroke: #e6e6e9; }

.rv-xy-plot__axis__tick__text {
  fill: #6b6b76;
  font-size: 11px; }

.rv-xy-plot__axis__title text {
  fill: #6b6b76;
  font-size: 11px; }

.rv-xy-plot__grid-lines__line {
  stroke: #e6e6e9; }

.rv-xy-plot__circular-grid-lines__line {
  fill-opacity: 0;
  stroke: #e6e6e9; }

.rv-xy-plot__series, .rv-xy-plot__series path {
  pointer-events: all; }

.rv-xy-plot__series--line {
  fill: none;
  stroke: #000;
  stroke-width: 2px; }

.rv-crosshair {
  position: absolute;
  font-size: 11px;
  pointer-events: none; }

.rv-crosshair__line {
  background: #47d3d9;
  width: 1px; }

.rv-crosshair__inner {
  position: absolute;
  text-align: left;
  top: 0; }

.rv-crosshair__inner__content {
  border-radius: 4px;
  background: #3a3a48;
  color: #fff;
  font-size: 12px;
  padding: 7px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }

.rv-crosshair__inner--left {
  right: 4px; }

.rv-crosshair__inner--right {
  left: 4px; }

.rv-crosshair__title {
  font-weight: bold;
  white-space: nowrap; }

.rv-crosshair__item {
  white-space: nowrap; }

.rv-hint {
  position: absolute;
  pointer-events: none; }

.rv-hint__content {
  border-radius: 4px;
  padding: 7px 10px;
  font-size: 12px;
  background: #3a3a48;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: left;
  white-space: nowrap; }

.rv-discrete-color-legend {
  box-sizing: border-box;
  overflow-y: auto;
  font-size: 12px; }

.rv-discrete-color-legend.horizontal {
  white-space: nowrap; }

.rv-discrete-color-legend-item {
  color: #3a3a48;
  border-radius: 1px;
  padding: 9px 10px; }

.rv-discrete-color-legend-item.horizontal {
  display: inline-block; }

.rv-discrete-color-legend-item.horizontal .rv-discrete-color-legend-item__title {
  margin-left: 0;
  display: block; }

.rv-discrete-color-legend-item__color {
  display: inline-block;
  vertical-align: middle;
  overflow: visible; }

.rv-discrete-color-legend-item__color__path {
  stroke: #dcdcdc;
  stroke-width: 2px; }

.rv-discrete-color-legend-item__title {
  margin-left: 10px; }

.rv-discrete-color-legend-item.disabled {
  color: #b8b8b8; }

.rv-discrete-color-legend-item.clickable {
  cursor: pointer; }

.rv-discrete-color-legend-item.clickable:hover {
  background: #f9f9f9; }

.rv-search-wrapper {
  display: flex;
  flex-direction: column; }

.rv-search-wrapper__form {
  flex: 0; }

.rv-search-wrapper__form__input {
  width: 100%;
  color: #a6a6a5;
  border: 1px solid #e5e5e4;
  padding: 7px 10px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 0 0 9px;
  outline: 0; }

.rv-search-wrapper__contents {
  flex: 1;
  overflow: auto; }

.rv-continuous-color-legend {
  font-size: 12px; }

.rv-continuous-color-legend .rv-gradient {
  height: 4px;
  border-radius: 2px;
  margin-bottom: 5px; }

.rv-continuous-size-legend {
  font-size: 12px; }

.rv-continuous-size-legend .rv-bubbles {
  text-align: justify;
  overflow: hidden;
  margin-bottom: 5px;
  width: 100%; }

.rv-continuous-size-legend .rv-bubble {
  background: #d8d9dc;
  display: inline-block;
  vertical-align: bottom; }

.rv-continuous-size-legend .rv-spacer {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  width: 100%; }

.rv-legend-titles {
  height: 16px;
  position: relative; }

.rv-legend-titles__left, .rv-legend-titles__right, .rv-legend-titles__center {
  position: absolute;
  white-space: nowrap;
  overflow: hidden; }

.rv-legend-titles__center {
  display: block;
  text-align: center;
  width: 100%; }

.rv-legend-titles__right {
  right: 0; }

.rv-radial-chart .rv-xy-plot__series--label {
  pointer-events: none; }

.egret-customizer {
  display: flex;
  flex-direction: column;
  width: 320px;
  position: fixed;
  right: 0;
  box-shadow: var(--elevation-z12);
  z-index: 50;
  top: 0;
  height: 100vh; }
  .egret-customizer .customizer-close {
    position: absolute;
    right: 8px;
    top: 8px; }
  .egret-customizer .layout-boxes {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px; }
    .egret-customizer .layout-boxes .layout-box {
      width: calc(50% - 16px);
      margin: 12px 8px;
      cursor: pointer; }
      .egret-customizer .layout-boxes .layout-box > div {
        overflow: hidden;
        display: flex;
        position: relative;
        height: 76px;
        width: 100%; }
        .egret-customizer .layout-boxes .layout-box > div img {
          position: absolute;
          top: 0;
          left: 0; }
  .egret-customizer .colors {
    display: flex;
    flex-wrap: wrap; }
    .egret-customizer .colors .color {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      margin-top: 4px;
      margin-right: 12px;
      margin-bottom: 12px;
      cursor: pointer;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
      .egret-customizer .colors .color .light, .egret-customizer .colors .color .dark {
        position: absolute;
        border: 12px solid transparent;
        transform: rotate(45deg);
        bottom: -12px;
        left: -12px;
        border-radius: 50%; }
      .egret-customizer .colors .color .light {
        border-top-color: rgba(215, 215, 215, 0.6); }
      .egret-customizer .colors .color .dark {
        border-top-color: rgba(0, 0, 0, 0.5); }

.loader-bounce {
  height: 100vh !important;
  width: 100%;
  display: flex;
  align-items: center; }

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: auto; }

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out; }

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

.egret-sidenav-container {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%; }
  .egret-sidenav-container .egret-sidenav {
    position: relative;
    transition: width 250ms ease;
    overflow: hidden;
    z-index: 91; }
    @media screen and (max-width: 767px) {
      .egret-sidenav-container .egret-sidenav {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0; } }
  .egret-sidenav-container .egret-sidenav-content {
    position: relative;
    flex: 1 1 0;
    height: 100%; }
  .egret-sidenav-container .egret-sidenav-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.74);
    z-index: 90; }

.egret-search-box {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 9; }
  .egret-search-box .search-box {
    outline: none;
    border: none;
    font-size: 1rem;
    height: calc(100% - 5px); }

.toolbar-menu-wrap {
  position: relative; }
  @media screen and (max-width: 959px) {
    .toolbar-menu-wrap .menu-area {
      position: fixed;
      background: #1a2038;
      height: 60px;
      width: 100%;
      left: 0;
      z-index: -10;
      opacity: 0;
      display: none;
      transition: all 0.15s ease;
      justify-content: flex-end; } }
  .toolbar-menu-wrap.open .menu-area {
    z-index: 9;
    opacity: 1;
    display: flex; }

.notification {
  width: 260px; }
  .notification .notification__topbar {
    height: 64px;
    box-shadow: var(--elevation-z6); }
  .notification .notification__card:hover .delete-button {
    cursor: pointer;
    display: unset;
    right: 0;
    margin-top: 6px;
    top: 0;
    z-index: 2; }
  .notification .notification__card:hover .card__topbar__time {
    display: none; }
  .notification .notification__card .delete-button {
    display: none;
    position: absolute;
    right: 0;
    margin-top: 9px; }
  .notification .notification__card .card__topbar__button {
    height: 24px;
    width: 24px;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9; }

.rectangle-box {
  height: 40px;
  width: 40px;
  min-width: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  overflow: hidden; }
  .rectangle-box .MuiIcon-root {
    font-size: 18px; }

.mini-cart {
  width: 260px; }
  .mini-cart .cart__topbar {
    height: 64px;
    box-shadow: var(--elevation-z6); }
  .mini-cart .mini-cart__item {
    transition: background 300ms ease; }
    .mini-cart .mini-cart__item:hover {
      background: rgba(0, 0, 0, 0.08); }
    .mini-cart .mini-cart__item img {
      width: 80px; }

.layout-full .container {
  padding-left: 30px;
  padding-right: 30px; }

.layout-contained .container, .layout-boxed .container {
  padding-left: 30px;
  padding-right: 30px; }

.layout-contained .container {
  max-width: 1200px;
  margin: auto;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .layout-contained .container {
      max-width: 100%; } }

.layout-boxed {
  max-width: 1200px;
  margin: auto;
  box-shadow: var(--elevation-z12);
  background: #ffffff; }
  @media screen and (max-width: 767px) {
    .layout-boxed {
      max-width: 100%;
      box-shadow: none; } }

.sidenav {
  position: fixed;
  top: 0;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  overflow: hidden;
  z-index: 99;
  box-shadow: var(--elevation-z8);
  width: 260px; }
  .sidenav .sidenav__hold {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    z-index: 3; }
    .sidenav .sidenav__hold .scrollable {
      padding-bottom: 120px; }
    .sidenav .sidenav__hold::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: -1; }
    .sidenav .sidenav__hold .brand-area {
      width: 260px;
      padding: 13px 18px; }
      .sidenav .sidenav__hold .brand-area .brand img {
        height: 24px;
        margin-right: 12px; }
      .sidenav .sidenav__hold .brand-area .brand .brand__text {
        font-weight: bold;
        font-size: 1.125rem; }
    .sidenav .sidenav__hold .sidenav__user {
      width: 260px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1.5rem 1rem 1rem; }
      .sidenav .sidenav__hold .sidenav__user .username-photo {
        display: flex;
        flex-direction: column;
        align-items: center; }
      .sidenav .sidenav__hold .sidenav__user img {
        height: 4rem;
        width: 4rem;
        border-radius: 50%;
        margin-bottom: 8px; }
      .sidenav .sidenav__hold .sidenav__user .username {
        font-weight: 300; }
        .sidenav .sidenav__hold .sidenav__user .username .material-icons {
          font-size: 13px; }
      .sidenav .sidenav__hold .sidenav__user .user__menu {
        display: flex;
        justify-content: center;
        padding: 1rem 0; }

.sidenav-mobile .sidenav {
  width: 260px; }

.sidenav-mobile .sidenav__overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.54);
  z-index: -1; }

.navigation button.nav-item {
  padding: 0 20px; }

.navigation .nav-item {
  display: flex;
  height: 48px;
  justify-content: space-between; }
  .navigation .nav-item button {
    padding: 0 20px; }
  .navigation .nav-item:hover {
    background: rgba(0, 0, 0, 0.2); }
  .navigation .nav-item.active {
    background: rgba(0, 0, 0, 0.2); }
  .navigation .nav-item > button {
    justify-content: flex-start; }
  .navigation .nav-item .item-icon {
    font-size: 18px; }
  .navigation .nav-item .item-text {
    font-size: 0.875rem; }
  .navigation .nav-item .icon-text {
    font-size: 0.875rem;
    display: inline-block;
    height: 21px;
    width: 21px;
    line-height: 21px; }
  .navigation .nav-item .item-arrow span {
    font-size: 18px; }

.navigation .submenu {
  position: relative;
  background: rgba(0, 0, 0, 0.12); }
  .navigation .submenu:before, .navigation .submenu:after {
    content: "";
    left: 0;
    position: absolute;
    width: 100%;
    height: 2px;
    z-index: 3; }
  .navigation .submenu:before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1), transparent);
    top: 0; }
  .navigation .submenu:after {
    background: linear-gradient(-180deg, rgba(0, 0, 0, 0.06), transparent);
    bottom: 0; }
  .navigation .submenu .nav-item {
    height: 44px; }

.badge {
  height: 20px;
  padding: 0 8px;
  margin: 0 4px 0 auto;
  border-radius: 12px;
  line-height: 20px;
  font-weight: 500; }

.footer {
  min-height: 64px; }

.sidenav,
.topbar,
.content-wrap {
  transition: all 0.3s ease; }

.sidenav .scrollable {
  width: 260px; }

.layout1 {
  height: 100%; }
  .layout1 .content-wrap {
    flex: 1 1 auto;
    display: flex;
    overflow: hidden;
    position: relative;
    flex-direction: column;
    height: 100%; }
    .layout1 .content-wrap .topbar {
      top: 0;
      z-index: 96;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 44%, rgba(247, 247, 247, 0.4) 50%, rgba(255, 255, 255, 0)); }
      .layout1 .content-wrap .topbar .topbar-hold {
        position: relative;
        box-shadow: var(--elevation-z8);
        height: 64px;
        border-radius: inherit;
        padding: 0 1rem; }
    .layout1 .content-wrap .scrollable-content {
      display: flex;
      flex-direction: column;
      position: relative;
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden; }
      .layout1 .content-wrap .scrollable-content .content {
        position: relative; }
  .layout1.sidenav-full .sidenav {
    left: 0; }
  .layout1.sidenav-full .content-wrap {
    width: calc(100% - 260px);
    vertical-align: top;
    margin-left: 260px; }
  .layout1.sidenav-compact .sidenav {
    width: 60px;
    overflow: hidden; }
    .layout1.sidenav-compact .sidenav .brand-area .brand__text {
      display: none; }
    .layout1.sidenav-compact .sidenav .scrollable .sidenav__user .username-photo {
      align-items: flex-start; }
    .layout1.sidenav-compact .sidenav .scrollable .sidenav__user img {
      height: 1.8rem;
      width: 1.8rem; }
    .layout1.sidenav-compact .sidenav .scrollable .sidenav__user .username,
    .layout1.sidenav-compact .sidenav .scrollable .sidenav__user .user__menu {
      display: none; }
    .layout1.sidenav-compact .sidenav .scrollable .navigation .nav-item {
      padding: 0;
      width: 48px;
      overflow: hidden;
      justify-content: center;
      margin-left: 6px;
      border-radius: 2px; }
      .layout1.sidenav-compact .sidenav .scrollable .navigation .nav-item .item-text,
      .layout1.sidenav-compact .sidenav .scrollable .navigation .nav-item .item-arrow,
      .layout1.sidenav-compact .sidenav .scrollable .navigation .nav-item .badge {
        display: none; }
      .layout1.sidenav-compact .sidenav .scrollable .navigation .nav-item > button {
        justify-content: center; }
    .layout1.sidenav-compact .sidenav:hover {
      width: 260px; }
      .layout1.sidenav-compact .sidenav:hover .brand-area .brand__text {
        display: inline-block; }
      .layout1.sidenav-compact .sidenav:hover .scrollable {
        width: 260px; }
        .layout1.sidenav-compact .sidenav:hover .scrollable .sidenav__user .username-photo {
          align-items: center; }
        .layout1.sidenav-compact .sidenav:hover .scrollable .sidenav__user img {
          height: 4rem;
          width: 4rem; }
        .layout1.sidenav-compact .sidenav:hover .scrollable .sidenav__user .username,
        .layout1.sidenav-compact .sidenav:hover .scrollable .sidenav__user .user__menu {
          display: flex; }
        .layout1.sidenav-compact .sidenav:hover .scrollable .navigation button.nav-item {
          padding: 0 20px; }
        .layout1.sidenav-compact .sidenav:hover .scrollable .navigation .nav-item {
          width: 100%;
          justify-content: space-between;
          margin-left: 0;
          border-radius: 0; }
          .layout1.sidenav-compact .sidenav:hover .scrollable .navigation .nav-item .item-text,
          .layout1.sidenav-compact .sidenav:hover .scrollable .navigation .nav-item .item-arrow,
          .layout1.sidenav-compact .sidenav:hover .scrollable .navigation .nav-item .badge {
            display: inline-block; }
          .layout1.sidenav-compact .sidenav:hover .scrollable .navigation .nav-item > button {
            justify-content: flex-start; }
  .layout1.sidenav-compact .content-wrap {
    width: calc(100% - 60px);
    vertical-align: top;
    margin-left: 60px; }
  .layout1.sidenav-close .sidenav {
    left: -260px; }
  .layout1.sidenav-close .content-wrap {
    width: 100%;
    vertical-align: top;
    margin-left: 0px; }
    .layout1.sidenav-close .content-wrap .topbar {
      width: 100%; }
  .layout1.sidenav-mobile .sidenav {
    width: 260px; }
  .layout1.sidenav-mobile .content-wrap {
    width: 100%;
    vertical-align: top;
    margin-left: 0px; }
    .layout1.sidenav-mobile .content-wrap .topbar {
      width: 100%; }

.layout2 {
  flex: 1 1 auto;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  height: 100%;
  transition: all .15s ease; }
  .layout2 .scrollable-content {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    width: 100%;
    overflow-y: auto; }
  .layout2.sidenav-close .sidenav {
    left: -260px; }

.layout2 .topbar {
  position: relative;
  width: 100%;
  display: table;
  height: 80px;
  border-bottom: 1px solid transparent;
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 98; }
  .layout2 .topbar .brand {
    height: 100%; }
    .layout2 .topbar .brand img {
      height: 32px; }
    .layout2 .topbar .brand .brand__text {
      font-weight: 500;
      font-size: 1.5rem;
      margin: 0 1rem; }
  .layout2 .topbar .MuiIconButton-root {
    color: #ffffff; }

.layout2 .navbar {
  position: relative;
  height: 60px;
  box-shadow: var(--elevation-z8);
  z-index: 98; }

.horizontal-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative; }

.horizontal-nav ul.menu {
  float: left;
  padding-right: 45px;
  margin-left: -20px;
  z-index: 99; }
  .horizontal-nav ul.menu > li {
    float: left; }
    .horizontal-nav ul.menu > li > div > a,
    .horizontal-nav ul.menu > li > div > div {
      border-bottom: 2px solid;
      height: 48px;
      box-sizing: border-box;
      border-color: transparent;
      margin: 0 6px; }

.horizontal-nav ul li {
  position: relative;
  margin: 0px;
  display: inline-block; }
  .horizontal-nav ul li ul a {
    padding: 8px 20px;
    height: 48px; }

.horizontal-nav a,
.horizontal-nav label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 20px;
  height: 60px;
  font-size: 0.875rem;
  text-decoration: none;
  box-sizing: border-box; }
  .horizontal-nav a .material-icons,
  .horizontal-nav label .material-icons {
    font-size: 14px;
    margin: 0 4px; }

.horizontal-nav ul ul {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  /* has to be the same number as the "line-height" of "nav a" */
  left: 20px;
  box-shadow: var(--elevation-z8);
  top: 60px;
  transform: translateY(-10px);
  transition: all 0.3s ease-in-out;
  z-index: -1; }

.horizontal-nav ul li:hover > div > div > ul,
.horizontal-nav ul li:hover > div > ul,
.horizontal-nav li:hover > ul {
  opacity: 1;
  visibility: visible;
  transform: translateY(0); }

.horizontal-nav ul ul li {
  width: 170px;
  float: none;
  display: list-item;
  position: relative; }

.horizontal-nav ul ul ul {
  top: 0;
  left: 170px; }

.horizontal-nav ul ul ul li {
  position: relative;
  top: 0; }

.horizontal-nav li > a:after {
  content: "arrow_drop_down";
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 1;
  margin-left: auto;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased; }

.horizontal-nav li > a:only-child:after {
  content: ""; }

.circular-image-small {
  height: 36px;
  width: 36px;
  border-radius: 50%; }

.bg-circle-primary {
  background: url("/assets/images/circles.png"), linear-gradient(90deg, var(--primary) -19.83%, var(--primary) 189.85%);
  background-size: cover;
  background-repeat: no-repeat; }

.bg-circle-secondary {
  background: url("/assets/images/circles.png"), linear-gradient(90deg, var(--secondary) -19.83%, var(--secondary) 189.85%);
  background-size: cover;
  background-repeat: no-repeat; }

.bg-circle-warn {
  background: url("/assets/images/circles.png"), linear-gradient(90deg, #e95455 -19.83%, #e95455 189.85%);
  background-size: cover;
  background-repeat: no-repeat; }

.signup {
  background: #1A2038; }
  .signup .signup-card {
    max-width: 800px;
    border-radius: 12px !important; }
    .signup .signup-card img {
      width: 200px; }

.left-sidenav-card {
  position: relative; }
  .left-sidenav-card .header-bg {
    height: 200px;
    background: var(--primary);
    background-image: url("/assets/images/home-bg-black.png");
    background-size: contain; }
  .left-sidenav-card .left-sidenav-card__content {
    margin-top: -200px;
    margin-right: 24px; }
    @media screen and (max-width: 767px) {
      .left-sidenav-card .left-sidenav-card__content {
        margin-right: 0px; } }
  .left-sidenav-card .left-sidenav-card__sidenav .sidenav__header {
    color: white !important; }
    @media screen and (max-width: 767px) {
      .left-sidenav-card .left-sidenav-card__sidenav .sidenav__header {
        color: inherit !important; } }
  @media screen and (max-width: 767px) {
    .left-sidenav-card .left-sidenav-card__sidenav {
      background: var(--bg-default); } }
  .left-sidenav-card .content-card .card-header {
    height: 64px; }

.user-profile {
  position: relative; }
  .user-profile .bg-light-primary {
    background: rgba(var(--primary), 0.1); }
  .user-profile .bg-light-green {
    background: rgba(8, 173, 108, 0.15) !important; }
  .user-profile .bg-light-error {
    background: rgba(233, 84, 85, 0.15) !important; }
  .user-profile .header-bg {
    height: 345px; }
    @media screen and (max-width: 959px) {
      .user-profile .header-bg {
        height: 400px; } }
    @media screen and (max-width: 767px) {
      .user-profile .header-bg {
        height: 400px; } }
  .user-profile .user-profile__content {
    margin-top: -345px;
    padding-top: 74px;
    padding-right: 30px;
    padding-left: 4px; }
    .user-profile .user-profile__content .menu-button {
      display: none; }
    @media screen and (max-width: 959px) {
      .user-profile .user-profile__content {
        margin-top: -390px;
        padding-top: 24px;
        padding-right: 16px;
        padding-left: 16px; } }
    @media screen and (max-width: 767px) {
      .user-profile .user-profile__content {
        margin-top: -410px;
        padding-top: 16px;
        padding-right: 16px;
        padding-left: 16px; }
        .user-profile .user-profile__content .menu-button {
          display: flex; } }
    .user-profile .user-profile__content .content__top-card-holder .content__top-card {
      height: 95px;
      background-color: rgba(0, 0, 0, 0.12); }
    .user-profile .user-profile__content .content__top-card-holder .content__chart {
      width: 54px;
      height: 35px; }
    .user-profile .user-profile__content .user-profile__card {
      overflow: unset; }
      .user-profile .user-profile__content .user-profile__card .card__edge-button {
        position: relative;
        margin-top: -56px; }
      .user-profile .user-profile__content .user-profile__card .edge-vertical-line::after {
        content: " ";
        position: absolute;
        height: 35px;
        width: 5px;
        top: -30px;
        background: var(--primary); }
      .user-profile .user-profile__content .user-profile__card .card__button-holder {
        width: 100px;
        min-width: 100px; }
      .user-profile .user-profile__content .user-profile__card .card__gray-box {
        height: 128px;
        width: calc(100% - 16px);
        border-radius: 8px;
        background-color: #dfdfdf; }
    .user-profile .user-profile__content .bills .bills__icon {
      border-radius: 8px;
      height: 52px;
      width: 52px;
      overflow: hidden;
      background-color: rgba(24, 42, 136, 0.08); }
      .user-profile .user-profile__content .bills .bills__icon h4,
      .user-profile .user-profile__content .bills .bills__icon h5 {
        color: rgba(0, 0, 0, 0.87); }
      .user-profile .user-profile__content .bills .bills__icon img {
        height: 23px;
        width: 36.76px; }
  .user-profile .user-profile__sidenav {
    margin-top: -345px;
    padding-top: 74px; }
    .user-profile .user-profile__sidenav .avatar {
      height: 82px;
      width: 82px; }
    .user-profile .user-profile__sidenav .sidenav__square-card {
      height: 104px;
      width: 104px; }
    @media screen and (max-width: 767px) {
      .user-profile .user-profile__sidenav {
        margin-top: -410px; } }

.invoice-viewer h5 {
  font-size: 15px; }

@media print {
  body,
  *,
  html {
    visibility: hidden; }
  .ps {
    overflow: scroll !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto; }
  #print-area {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%; }
    #print-area * {
      visibility: visible; } }

.rbc-event.rbc-selected {
  background-color: var(--primary) !important; }

.rbc-calendar {
  height: auto;
  flex-grow: 1; }

.rbc-header {
  padding: 12px 16px !important; }
  .rbc-header a {
    padding-bottom: 8px !important; }
  .rbc-header span {
    font-size: 15px !important;
    font-weight: 500; }

.calendar-header {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px; }

.crud-table thead tr th:first-child {
  padding-left: 16px !important; }

.crud-table tbody tr {
  transition: background 300ms ease; }
  .crud-table tbody tr:hover {
    background: rgba(0, 0, 0, 0.08); }
  .crud-table tbody tr td {
    border-bottom: none;
    text-transform: capitalize; }
  .crud-table tbody tr td:first-child {
    padding-left: 16px !important; }

.inbox .inbox__topbar {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px; }
  .inbox .inbox__topbar button {
    color: white !important; }

.ql-container {
  min-height: 250px; }
  .ql-container p,
  .ql-container code {
    font-size: 16px; }

.ql-toolbar {
  background: white;
  border-bottom: none; }

.chat-sidenav {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  height: 450px; }
  .chat-sidenav .chat-contact-list {
    height: 100%; }

.chat-container {
  background: rgba(0, 0, 0, 0.05);
  height: 450px; }
  .chat-container .chat-message-list .list__message {
    border-radius: 4px;
    overflow: hidden; }
  .chat-container .empty-message-circle {
    height: 220px;
    width: 220px;
    border-radius: 50%;
    box-shadow: var(--elevation-z6); }
    .chat-container .empty-message-circle .MuiIcon-root {
      font-size: 4rem !important; }

.todo .todo__search-box-holder {
  background: var(--primary);
  height: 220px; }
  .todo .todo__search-box-holder > div {
    height: calc(220px - 94px + 30px); }
    @media screen and (max-width: 767px) {
      .todo .todo__search-box-holder > div {
        height: calc(220px - 94px - 16px + 30px); } }
    .todo .todo__search-box-holder > div .todo__search-box {
      width: calc(100% - 60px);
      height: 48px;
      border-radius: 24px;
      overflow: hidden; }
      .todo .todo__search-box-holder > div .todo__search-box input[type="text"] {
        font-size: 18px;
        outline: none;
        border: none; }

.todo .todo__content {
  margin-top: -94px; }

.sales .bills .bills__icon {
  border-radius: 8px;
  height: 52px;
  width: 52px;
  overflow: hidden;
  background-color: rgba(24, 42, 136, 0.08); }
  .sales .bills .bills__icon h4,
  .sales .bills .bills__icon h5 {
    color: rgba(0, 0, 0, 0.87); }
  .sales .bills .bills__icon img {
    height: 23px;
    width: 36.76px; }

.sales .product-table {
  white-space: pre;
  min-width: 400px;
  overflow: auto; }
  .sales .product-table small {
    height: 15px;
    width: 50px;
    border-radius: 500px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24); }
  .sales .product-table tbody tr {
    transition: background 300ms ease; }
    .sales .product-table tbody tr:hover {
      background: rgba(0, 0, 0, 0.08); }
    .sales .product-table tbody tr td {
      border-bottom: none;
      text-transform: capitalize; }
    .sales .product-table tbody tr td:first-child {
      padding-left: 16px !important; }

.analytics .face-group .avatar {
  border: 2px solid white; }
  .analytics .face-group .avatar:not(:first-child) {
    margin-left: -14px; }

.analytics .face-group .number-avatar {
  background: #e95455; }

.analytics .small-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  border-radius: 50%; }
  .analytics .small-circle .small-icon {
    font-size: 8px; }

.analytics .project-card .card__roject-name {
  margin-left: 24px; }
  @media screen and (max-width: 767px) {
    .analytics .project-card .card__roject-name {
      margin-left: 4px; } }

.learning-management {
  position: relative; }
  .learning-management .welcome-card {
    position: relative;
    padding: 36px 50px !important;
    overflow: visible; }
    .learning-management .welcome-card img {
      margin-top: -82px;
      max-width: 230px; }
    @media screen and (max-width: 767px) {
      .learning-management .welcome-card img {
        display: none; } }
  .learning-management .play-card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .learning-management .play-card small {
      line-height: 1; }
  .learning-management .product-table {
    white-space: pre;
    min-width: 400px;
    overflow: auto; }
    .learning-management .product-table small {
      height: 15px;
      width: 50px;
      border-radius: 500px;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24); }
    .learning-management .product-table tbody tr {
      transition: background 300ms ease; }
      .learning-management .product-table tbody tr:hover {
        background: rgba(0, 0, 0, 0.08); }
      .learning-management .product-table tbody tr td {
        border-bottom: none;
        text-transform: capitalize; }
      .learning-management .product-table tbody tr td:first-child {
        padding-left: 16px !important; }
  .learning-management .upgrade-card {
    box-shadow: none;
    text-align: center;
    position: relative; }
    .learning-management .upgrade-card h6 {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 150px; }

.list .list-view .list__card .project-image {
  height: 75px;
  width: 100px; }

.list .list-view .list__card .card__button-group {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  right: 0; }

.list .list-view .list__card:hover .card__button-group {
  display: flex; }

.list .grid-view .grid__card {
  position: relative; }
  .list .grid-view .grid__card:hover .grid__card-top::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.54);
    z-index: 1;
    animation: fade-in 250ms cubic-bezier(0.17, 0.67, 0.83, 0.67); }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .list .grid-view .grid__card:hover .grid__card-top .grid__card-overlay {
    display: block; }
  .list .grid-view .grid__card:hover .grid__card-bottom .email {
    display: block; }
  .list .grid-view .grid__card:hover .grid__card-bottom .date {
    display: none; }
  .list .grid-view .grid__card .grid__card-top {
    position: relative; }
    .list .grid-view .grid__card .grid__card-top .grid__card-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: none;
      z-index: 2; }
      .list .grid-view .grid__card .grid__card-top .grid__card-overlay > div:nth-child(2) {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1; }
  .list .grid-view .grid__card .grid__card-bottom .email {
    display: none; }

.landing a {
  text-decoration: none; }

.landing img {
  max-width: 100%; }

.landing div,
.landing section {
  box-sizing: border-box; }

.landing .light-gray {
  background: rgba(0, 0, 0, 0.024); }

.landing .bg-img-indigo {
  background: url(/assets/images/home-bg.jpg) center center/cover no-repeat !important; }

.landing .bg-img-black {
  background: url(/assets/images/home-bg-black.png) center center/cover no-repeat !important; }

.landing .container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px; }

.landing .text-center {
  text-align: center; }

.landing .text-sm {
  font-size: 0.813rem; }

.landing .fs-1 {
  font-size: 1rem !important; }

.landing .fw-400 {
  font-weight: 400; }

.landing .font-light {
  font-weight: 300 !important; }

.landing .text-capitalize {
  text-transform: capitalize; }

.landing .text-uppercase {
  text-transform: uppercase; }

.landing .text-primary {
  color: var(--primary); }

.landing .text-secondary {
  color: var(--secondary); }

.landing .relative {
  position: relative; }

.landing .elevation-3 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12); }

.landing .card {
  transition: all 0.3s ease; }
  .landing .card:hover {
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2); }

.landing .subscribe-input {
  display: inline-flex;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  border-radius: 2rem;
  overflow: hidden;
  background: #ffffff;
  min-width: 480px; }
  .landing .subscribe-input .email-input {
    border: 0;
    font-size: 1rem;
    flex-grow: 1;
    padding: 0;
    line-height: 1;
    margin: 0;
    background: inherit; }
    .landing .subscribe-input .email-input:focus {
      outline: 0; }
  .landing .subscribe-input .btn-action {
    margin: 0 !important; }
  @media only screen and (max-width: 767px) {
    .landing .subscribe-input {
      max-width: 90%;
      min-width: auto; } }

.landing .rounded-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 64px;
  border: 2px solid rgba(var(--primary), 0.8);
  color: var(--primary);
  background: rgba(var(--primary), 0.2); }
  .landing .rounded-icon .material-icons {
    font-size: 1.5rem; }
  .landing .rounded-icon.secondary {
    border: 2px solid rgba(var(--secondary), 0.8);
    color: var(--secondary);
    background: rgba(var(--secondary), 0.2); }

.landing .section {
  padding: 80px 0; }
  .landing .section .section__header {
    margin: 0 0 32px; }
    .landing .section .section__header h2 {
      font-size: 32px;
      font-weight: 700;
      margin: 0 0 16px; }
    .landing .section .section__header p {
      font-size: 16px;
      max-width: 36rem;
      margin: 0; }

.landing .btn-action {
  padding: 0 24px !important;
  font-size: 18px !important; }

.landing .btn-white {
  background: #ffffff !important;
  color: rgba(0, 0, 0, 0.87) !important; }
  .landing .btn-white:hover {
    background: #f2f2f2 !important; }

.header {
  position: fixed;
  width: 100%;
  background: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  transition: padding 0.3s linear;
  -webkit-transition: padding 0.3s linear;
  z-index: 2; }
  .header.header-fixed {
    padding: 10px 0;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28); }
  .header .header-container {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .header .header-container .brand {
      display: flex;
      align-items: center; }
      .header .header-container .brand img {
        max-height: 34px; }
    .header .header-container ul.navigation {
      list-style: none;
      margin: 0 0 0 30px;
      padding: 0; }
      .header .header-container ul.navigation li {
        display: inline-block; }
        .header .header-container ul.navigation li a {
          display: flex;
          align-items: center;
          color: rgba(0, 0, 0, 0.87);
          padding: 8px 15px;
          font-weight: 700;
          border-radius: 4px;
          transition: all 0.3s ease-in; }
          .header .header-container ul.navigation li a:hover {
            background: #f0f0f0; }
    .header .header-container .header__toggle {
      display: none; }
  @media only screen and (max-width: 767px) {
    .header {
      width: 220px;
      height: 100vh;
      left: auto;
      right: 0;
      padding: 20px 0 !important;
      box-shadow: -2px 0 4px rgba(0, 0, 0, 0.14), -2px 4px 8px rgba(0, 0, 0, 0.28) !important;
      transition: all 0.3s ease-in-out; }
      .header.closed {
        right: calc(-220px - 5px); }
        .header.closed .header__toggle {
          color: rgba(0, 0, 0, 0.87);
          background: #ffffff;
          box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }
      .header .header__toggle {
        top: 10px;
        right: 15px;
        color: rgba(0, 0, 0, 0.87);
        position: fixed;
        display: inline-block !important;
        transition: all 0.3s ease-in-out; }
      .header .header-container {
        flex-direction: column;
        padding: 0;
        height: 100%; }
        .header .header-container .brand {
          padding: 0 20px 20px;
          width: 100%; }
        .header .header-container ul.navigation {
          margin: 0;
          width: 100%; }
          .header .header-container ul.navigation li {
            display: block; }
            .header .header-container ul.navigation li a {
              padding: 15px 20px;
              border-radius: 0; } }

.section-intro1 {
  padding: 180px 0 !important;
  color: #ffffff;
  overflow: hidden; }
  .section-intro1 h1,
  .section-intro1 h2,
  .section-intro1 h3,
  .section-intro1 h4,
  .section-intro1 h5,
  .section-intro1 h6 {
    color: #ffffff; }
  .section-intro1 .section-intro1__title {
    margin: 0 0 24px;
    font-size: 48px;
    font-weight: 900;
    line-height: 52px;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.22); }
  .section-intro1 .section-intro1__subtitle {
    margin: 0 0 40px;
    font-size: 22px;
    font-weight: 400;
    line-height: 32px; }
  .section-intro1 .section-intro1__list {
    margin: 0 0 30px; }
    .section-intro1 .section-intro1__list .section-intro1__list__item {
      display: flex;
      align-items: center;
      margin-bottom: 1rem; }
      .section-intro1 .section-intro1__list .section-intro1__list__item .material-icons {
        margin-right: 7px; }
  .section-intro1 .section-intro1__product {
    position: relative;
    top: 100px; }
    .section-intro1 .section-intro1__product img {
      width: 100%;
      border-radius: 0.5rem;
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }
    .section-intro1 .section-intro1__product .section-intro1__product__link {
      position: absolute;
      top: -100px;
      left: -30px;
      text-align: center;
      display: block;
      width: 160px;
      height: 160px;
      border-radius: 50%;
      background: #ffffff;
      color: rgba(0, 0, 0, 0.87);
      padding: 37px 20px;
      box-sizing: border-box;
      overflow: hidden;
      box-shadow: 0 14px 32px rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease; }
      .section-intro1 .section-intro1__product .section-intro1__product__link:hover {
        box-shadow: 0 19px 32px rgba(0, 0, 0, 0.4); }
      .section-intro1 .section-intro1__product .section-intro1__product__link .price {
        font-size: 32px;
        font-weight: 700; }
      .section-intro1 .section-intro1__product .section-intro1__product__link .price__text {
        font-size: 14px; }
  .section-intro1 .intro1__description {
    max-width: 570px;
    margin: 0 auto 24px; }
  @media only screen and (max-width: 767px) {
    .section-intro1 {
      padding: 100px 0 !important; } }
  @media only screen and (max-width: 959px) {
    .section-intro1 {
      text-align: center; }
      .section-intro1 .section-intro1__list {
        display: flex;
        flex-direction: column;
        align-items: center; }
      .section-intro1 .section-intro1__product {
        top: 0;
        left: 0; }
      .section-intro1 .section-intro1__product__link {
        display: none !important; } }

.section-intro2 {
  padding: 180px 0 0 !important;
  overflow: visible !important; }
  .section-intro2 h1,
  .section-intro2 h2,
  .section-intro2 h3,
  .section-intro2 h4,
  .section-intro2 h5,
  .section-intro2 h6 {
    color: #ffffff; }
  .section-intro2 .section-intro2__product {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    margin: auto;
    bottom: -80px;
    max-width: 780px;
    margin-bottom: 5rem; }
    .section-intro2 .section-intro2__product img {
      border-radius: 0.5rem;
      box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 767px) {
    .section-intro2 {
      padding: 100px 0 0 !important; }
      .section-intro2 .section-intro2__product {
        bottom: -63px; } }
  @media screen and (max-width: 580px) {
    .section-intro2 .section-intro2__product {
      bottom: -39px; } }

.section-intro3 {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87); }
  .section-intro3 .section-intro1__title {
    color: var(--primary); }
  .section-intro3 .intro3__product {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2.5rem; }
    .section-intro3 .intro3__product img {
      max-width: 65%; }

.section-portfolio1 .portfolio1__card {
  position: relative;
  height: 100%; }
  .section-portfolio1 .portfolio1__card img {
    width: 100%;
    height: auto; }
  .section-portfolio1 .portfolio1__card h5 {
    margin: 0px;
    font-size: 1rem;
    font-weight: bold; }
  .section-portfolio1 .portfolio1__card p {
    margin-bottom: 16px; }
  .section-portfolio1 .portfolio1__card .portfolio1__card__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

.card__icon-64 {
  font-size: 64px !important;
  height: 64px;
  width: 64px; }

.service__card:hover .card__icon-64 {
  color: var(--primary); }

.section-service1 .service1__card {
  border-top: 2px solid black;
  height: 100%; }
  .section-service1 .service1__card h3 {
    font-size: 1.5rem;
    font-weight: normal; }
  .section-service1 .service1__card .service1__card__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%; }
  .section-service1 .service1__card:hover {
    border-top: 2px solid var(--primary); }

.section-service4 {
  transition: transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67); }
  .section-service4 .service4__card {
    position: relative;
    overflow: hidden; }
    .section-service4 .service4__card .service4__card__icon {
      position: absolute;
      font-size: 200px;
      height: 200px;
      width: 200px;
      left: -80px;
      bottom: -80px;
      z-index: 1;
      opacity: 0.24;
      transition: transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67); }
    .section-service4 .service4__card .service4__card__description {
      max-width: 370px;
      text-align: left;
      vertical-align: middle;
      padding: 32px;
      margin-left: 96px;
      z-index: 2; }
    .section-service4 .service4__card:hover {
      transform: translateY(-8px); }
      .section-service4 .service4__card:hover .service4__card__icon {
        transform: translateY(-8px);
        color: var(--secondary);
        opacity: 0.6; }

.section-service5 .service5__image-holder img {
  border-radius: 4px;
  overflow: hidden; }

.section-service5 p {
  white-space: pre-line; }

.section-service5 .service5__link-icon {
  font-size: inherit;
  vertical-align: middle; }

.section-service6 .sevice6__circle {
  width: 112px;
  height: 112px;
  margin: 0px auto;
  background: #fff;
  border-radius: 50%;
  overflow: hidden; }

.section-service7 img {
  max-height: 280px; }

.section-service7 .service7__card__description {
  max-width: 370px;
  text-align: left; }
  .section-service7 .service7__card__description p {
    white-space: pre-line; }

.section-service7 .service7__card {
  background-image: url("/assets/images/illustrations/1.svg");
  background-size: 250px;
  background-repeat: no-repeat;
  background-position: center right; }
  .section-service7 .service7__card .service7__card__icon {
    font-size: 48px; }

.section-testimonial1 .testimonial1__card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%; }
  .section-testimonial1 .testimonial1__card-content > div:first-child img {
    height: 36px !important;
    width: auto; }
  .section-testimonial1 .testimonial1__card-content > div:first-child p {
    font-size: 1rem;
    padding-bottom: 65px; }
  .section-testimonial1 .testimonial1__card-content .card__user {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center; }
    .section-testimonial1 .testimonial1__card-content .card__user img {
      width: 48px;
      height: 48px;
      border-radius: 24px;
      overflow: hidden; }

.section-testimonial3 img {
  margin: 0px;
  padding: 0px;
  height: 48px;
  width: 48px;
  vertical-align: middle;
  border-radius: 50%;
  overflow: hidden; }

.section-testimonial3 .test {
  background: red; }

.section-cta1 {
  color: #fff;
  background: url(/assets/images/home-bg.jpg) center center/cover no-repeat; }
  .section-cta1 h1,
  .section-cta1 h2,
  .section-cta1 h3,
  .section-cta1 h4,
  .section-cta1 h5,
  .section-cta1 h6 {
    color: #ffffff; }
  .section-cta1 h2 {
    font-size: 1.75rem;
    margin: 0;
    padding-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.1; }
  .section-cta1 p {
    margin: 0; }

.section-pricing1 .pricing1__card-header {
  padding: 12px;
  background: rgba(0, 0, 0, 0.024); }
  .section-pricing1 .pricing1__card-header span:first-child {
    font-size: 1rem !important; }
  .section-pricing1 .pricing1__card-header span:last-child {
    font-size: 0.813rem; }

.section-pricing1 .pricing1__highlighted {
  background: var(--primary) !important; }
  .section-pricing1 .pricing1__highlighted span {
    color: #fff; }

.section-pricing1 .pricing1__card-content h1 {
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 2rem; }

.section-pricing1 .pricing1__card-content div {
  padding: 10px; }

.section-footer1 h4 {
  font-size: 1.25rem;
  margin: 0 0 24px;
  position: relative; }

.section-footer1 h4::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  height: 2px;
  width: 64px;
  background-color: var(--secondary); }

.section-footer1 .footer1__contact h5 {
  font-size: 1rem; }

.section-footer1 .footer1__contact > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }

.section-footer1 .footer1__contact .footer1__contact__icon {
  color: var(--secondary); }

.section-footer1 .footer1__disclaimer__link img {
  height: 24px;
  width: 24px; }

.section-footer1 .footer1__contact,
.section-footer1 .footer1__disclaimer,
.section-footer1 .footer1__about {
  padding: 30px;
  height: 100%;
  box-shadow: 3px 0 4px rgba(0, 0, 0, 0.2); }

.swiper-slide {
  height: auto; }

.swiper-pagination-bullet {
  opacity: 1;
  background: rgba(0, 0, 0, 0.55);
  transition: transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67); }

.bullet-active {
  transform: scale(1.8); }

.carousel__button-next,
.carousel__button-prev {
  position: absolute !important;
  top: 50%;
  transform: translateY(calc(-50% - 50px));
  z-index: 1; }

.carousel__button-prev {
  left: 0px; }

.carousel__button-next {
  right: 0px; }

.pricing .pricing__card {
  border-radius: 20px;
  overflow: hidden; }
  .pricing .pricing__card h1,
  .pricing .pricing__card h5 {
    margin: 0;
    color: var(--primary) !important;
    text-transform: uppercase; }
  .pricing .pricing__card h5 {
    font-weight: 400;
    letter-spacing: 3px; }
  .pricing .pricing__card h1 {
    line-height: 1;
    font-size: 3rem;
    padding-top: 8px;
    padding-bottom: 4px;
    font-weight: 500; }
  .pricing .pricing__card p {
    color: var(--text-muted);
    font-size: 1rem; }
  .pricing .pricing__card img {
    height: 150px;
    width: 150px; }

.upload-drop-box {
  height: 120px;
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px; }

.drag-shadow {
  background: var(--primary);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2); }

.scrum-board .face-group .avatar,
.scrum-board .face-group-36 .avatar {
  border: 2px solid white;
  height: 24px;
  width: 24px; }
  .scrum-board .face-group .avatar:not(:first-child),
  .scrum-board .face-group-36 .avatar:not(:first-child) {
    margin-left: -8px; }

.scrum-board .face-group .number-avatar,
.scrum-board .face-group-36 .number-avatar {
  font-size: 12px;
  background: #e95455; }

.scrum-board .face-group-36 .avatar {
  height: 36px;
  width: 36px; }
  .scrum-board .face-group-36 .avatar:not(:first-child) {
    margin-left: -12px; }

.scrum-board .face-group-36 .number-avatar {
  font-size: 14px; }

.scrum-board .button-group button {
  min-width: 32px !important; }

.scrum-board .list-column {
  margin: 0px 12px; }
  .scrum-board .list-column .list-column__card {
    margin-bottom: 16px; }
  .scrum-board .list-column .list-column__card:last-child {
    margin-bottom: 0px; }

.scrum-board .list-column:first-child {
  margin: 0px 12px 0px 0px; }

.cart {
  min-width: 900px;
  overflow-x: scroll; }

.ecommerce__product-card {
  position: relative; }
  .ecommerce__product-card .product__image-box .product__price {
    position: absolute;
    font-weight: 500;
    background: var(--primary);
    color: white;
    padding: 4px 12px;
    right: 0;
    top: 24px;
    border-top-left-radius: 26px;
    border-bottom-left-radius: 26px;
    overflow: hidden;
    z-index: 4; }
  .ecommerce__product-card .product__image-box .image-box__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    background: rgba(0, 0, 0, 0.74);
    z-index: 2; }
  .ecommerce__product-card:hover .image-box__overlay {
    display: flex;
    justify-content: center;
    align-items: center; }

.checkout .checkout__product-list hr:last-of-type {
  display: none !important; }
